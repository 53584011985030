.transportMain {
  grid-area: menu / menu / menu / content;
  grid-template-areas: "list mapcontent";
  box-sizing: border-box;
  height: 100vh;
  grid-template-columns: 300px 1fr;
}
.mapContainer {
  grid-area: mapcontent;
}
.leaflet-right {
  display: none;
}
