.modalContainer {
  position: relative;
}

.closeModal {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px 10px 0 0;
}
