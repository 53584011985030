@import "../../../styles/variables";
@import "../../../styles/editor_function";
@import "../../../styles/button";
@import "../../../styles/button_clear";

.amplify-expander__header {
  display: flex;
  background: white;
  border-radius: 4px;
  &:hover {
    background: #eff0f0;
  }
}
.amplify-expander__trigger {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  padding-left: 32px;
  min-height: 60px;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  color: #646464;
}
.amplify-expander__icon {
  padding-right: 10px;
}

/*.logModuleContainer {
  min-height: 150px;
}*/

.logModuleTable {
  margin-left: 32px;
  width: 80%;
  border-top: $Border2;
}

@include Btn(11.25, 2.5);
@include BtnClear(11.25, 2.5);
