@import "../styles/variables";

@mixin BtnClear($width, $height) {
  .clear {
    display: flex;
    justify-content: center;
    align-items: center;
    width: #{$width}rem;
    height: #{$height}rem;
    background: $Color15;
    border-radius: 4px;
    span {
      font-style: normal;
      font-size: 16px;
      line-height: 23.68px;
      text-align: center;
      color: $BgColor;
    }
  }
}
