@import "../../styles/button";

.verification_container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}
.verification_icon {
  padding: 20px;
  text-align: -webkit-center;
}
.verification_title {
  margin-top: 30px;
  padding: 20px;
  color: #1f5ea8;
  text-align: center;
  font-size: 64px;
  font-style: normal;
  font-weight: 900;
  line-height: 140%;
}
.verification_subtitle {
  color: #1f5ea8;
  text-align: center;
  font-size: 35px;
}
@include Btn(25, 3.75);
