@import "../../../styles/button_cancel";

.modal_overlay {
  grid-area: mapcontent;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  overflow-y: hidden;
  .modal_container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .modal_form {
      padding-top: 32px;
      padding-left: 32px;
      padding-right: 32px;
      width: 452px;
      height: 217px;
      background-color: #ffffff;
      box-shadow:
        0 1px 3px 0 rgba(63, 63, 68, 0.15),
        0 1px 0 0 rgba(63, 63, 68, 0.05);
      .modal_header {
        display: flex;
        align-items: baseline;
        h2 {
          color: #646464;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
        }
        img {
          width: 20px;
          height: 20px;
          cursor: pointer;
        }
      }
      .modal_body {
        margin-top: 8px;
        p {
          color: #646464;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
      }
    }
  }

  @include BtnCancel(180px, 44px);
}
