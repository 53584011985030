@import "../../../styles/variables";
@import "../../../styles/title";
@import "../../../styles/button";
@import "../../../styles/span";
.archived_transport {
  grid-area: mapcontent;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 6;
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  .archived_transport_container {
    position: absolute;
    left: 60%;
    top: 50%;
    transform: translate(-60%, -50%);
  }
  .archived_modal_form {
    padding: 32px;
    width: 452px;
    height: 484px;
    background-color: $BgColor;
    border-radius: 4px;
    .modal_form_title {
      @include Title(24);
    }
    .modal_form_body {
      span {
        padding-left: 0;
        font-size: 18px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: -0.0364583px;
        color: $Color4;
      }
    }
    .from_subtitle {
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      color: $Color8;
      line-height: 24px;
    }
  }
  .modal_buttons {
    @include Btn(11.25, 2.5);
  }
}
