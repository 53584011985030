@import "../../styles/editor_function";
@import "../../styles/variables";
@import "../../styles/button";
@import "../../styles/title";
@import "../../styles/InputTextarea";
@import "../../styles/button_modules_settings";

.editorList {
  grid-area: mapcontent;
  @include EditorFunctionContainer(32, 30);
  @include FunctionEditorHeader(flex, 20);
  @include Title(36);
  @include Btn(11.25, 2.5);
  @include BtnSettings(12.3125, 2.5);
  .back {
    padding-right: 5px;
    cursor: pointer;

    svg {
      &.active {
        path {
          stroke: #3bbc3c;
        }
      }

      &:hover {
        path {
          stroke: #3bbc3c;
        }
      }
    }
  }
  .editorBlockContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    box-shadow: $BXShadow;
    background: #ffffff;
    border-radius: $BorderRadius;
    .itemBlock {
      padding: 32px;
      .descriptionBlock {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 32px;

        span {
          font-weight: 400;
          font-size: 16px;
          line-height: 148%;
        }
        input {
          padding: 12px;
          border: 1px solid $Color7;
          border-radius: 2px;
          box-shadow: 0 0 1px 1px $Color7;

          &:focus {
            border-color: $Color11;
            box-shadow: 0 0 1px 1px $Color11;
          }
          &.disabled {
            background-color: #ebebeb;
          }
        }
      }
      input {
        width: 100%;
        height: 100%;
        background: $BgColor;
        font-weight: 400;
        font-size: 0.875rem;
        outline: none;
        line-height: 140%;
        cursor: pointer;
      }
      .coordinates,
      .transport_UVI {
        background-color: #ebebeb;
        cursor: none;
        pointer-events: none;
        box-shadow: none;
        border: none;
      }
    }

    .mapBlock {
      position: relative;
      grid-column: span 2;
      z-index: 1;
      height: 300px;
    }
    .open-modal-button {
      gap: 5px;
      position: absolute;
      top: 10px;
      right: 10px;
      background-color: rgba(53, 169, 54, 1);
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      z-index: 1001;
    }

    .open-modal-button-size {
      width: 9.25rem;
    }
    .notesBlock {
      display: flex;
      flex-direction: column;
    }
    .notes {
      height: 200px;
    }
    textarea {
      width: 100%;
      height: 100%;
      outline: none;
      padding: 12px 0 12px 12px;
      overflow: hidden;
      overflow-y: scroll;
      resize: none;
      border: 1px solid $Color7;
      border-radius: 2px;
      box-shadow: 0 0 1px 1px $Color7;

      &:focus {
        border-color: $Color11;
        box-shadow: 0 0 1px 1px $Color11;
      }
      &.disabled {
        background-color: #ebebeb;
      }

      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-track {
        border-radius: 50px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 50px;
        background: var(--grayscale-spacer-light, #e5e0eb);
      }
    }

    p {
      color: #ec4c47;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
    }
  }
}
