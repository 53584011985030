@import "../styles/variables";
@mixin Description {
  .descriptionBlock {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 4px;
    .descriptionBlock_span {
      padding-bottom: 4px;
      font-weight: 400;
      font-size: 16px;
      line-height: 148%;
    }
    input,
    textarea {
      padding: 12px;
      border: 1px solid $Color7;
      border-radius: 2px;
      box-shadow: 0 0 1px 1px $Color7;
      &:focus {
        border-color: $Color11;
        box-shadow: 0 0 1px 1px $Color11;
      }
      &.disabled {
        background-color: #ebebeb;
      }
    }
    .searchInputGroup {
      padding: 8px 10px 9px 10px;
    }
    .searchInputGroup,
    /*.newGroupInput*/ {
      &:focus {
        border: 1px solid $Color7;
        box-shadow: 0 0 1px 1px $Color7;
      }
    }
  }
}
