@import "../../../../styles/variables";
@import "../../../../styles/button";
@import "../../../../styles/button_cancel";
@import "../../../../styles/InputTextarea";

.modal_group {
  grid-area: mapcontent;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 6;
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  .modal_group_form {
    padding-top: 20px;
    padding-left: 32px;
    padding-bottom: 32px;
    width: 346px;
    max-height: 430px;
    flex-shrink: 0;
    border-radius: 4px;
    background-color: #fff;
    box-shadow:
      0 1px 0 0 rgba(63, 63, 68, 0.05),
      0 4px 4px 0 rgba(0, 0, 0, 0.25);
    .modal_group_title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 16px;
      h3 {
        color: #646464;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
      }
      img {
        width: 16px;
        height: 16px;
        cursor: pointer;
      }
    }
  }
  .group_body {
    padding-right: 32px;
    .group_search {
      border: $BorderBottom;
      background-color: $Color5;
      .searchImage {
        background-color: $Color5;
        padding-left: 9px;
      }
      .searchInputGroup {
        @include InputTextarea(60%, 2.25);
        background-color: $Color5;
        color: #9ea0a5;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.038px;
      }
    }
    .group_button_title,
    .groupName {
      color: #646464;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: -0.036px;
    }
    .group_list {
      overflow-y: auto;
      scroll-snap-type: y mandatory;
      height: 150px;
      margin-top: 8px;
      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-track {
        border-radius: 50px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 50px;
        background: var(--grayscale-spacer-light, #e5e0eb);
      }
      li {
        margin-right: 4px;
        cursor: pointer;
        .groupName {
          padding-top: 10px;
          padding-bottom: 10px;
          padding-left: 10px;
        }
        &.active {
          background-color: $Color5;
        }
      }
    }
  }
  .new_group {
    display: flex;
    align-items: center;
    margin-top: 8px;
  }
  .back {
    display: flex;
    width: 282px;
    padding: 8px 28px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    border: 1px solid #646464;
    background: #fff;
    span {
      color: #646464;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 148%;
    }
  }
  @include Btn(17.625, 2.5);
  @include BtnCancel(282, 40);

  @media screen and (min-width: 990px) and (max-width: 1920px) {
    .modal_group_container {
      position: absolute;
      left: 30%;
      top: 33%;
      transform: translate(-33%, -30%);
    }
  }
  @media screen and (min-width: 1920px) {
    .modal_group_container {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
