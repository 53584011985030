@import "../../styles/variables";
@import "../../styles/title";
@import "../../styles/InputTextarea";
@import "../../styles/button";
@import "../../styles/button_cancel";
.overlay {
  grid-area: mapcontent;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  overflow-y: hidden;
  .changePasswordContainer {
    position: absolute;
    left: 60%;
    top: 50%;
    transform: translate(-60%, -50%);
    .changePasswordForm {
      border-radius: 0.25rem;
      width: 28.25rem;
      max-height: 460px;
      background-color: $BgColor;
      padding: 32px;
      .passwordFormHeader {
        display: flex;
        align-items: center;
        @include Title(24);
        img {
          width: 1rem;
          height: 1rem;
          margin-left: auto;
          cursor: pointer;
        }
      }
      .passwordFormDescription {
        margin-top: 0.625rem;
        .formDescriptionBlock {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          gap: 4px;
          label {
            font-weight: 700;
            padding-bottom: 0.25rem;
            font-size: 1rem;
            line-height: 148%;
            color: $Color1;
          }
          input {
            @include InputTextarea(24.25, 2.75);
            border: 1px solid #646464;
            padding: 0.75rem;
            border-radius: 0.25rem;
          }
        }
        p {
          color: #ec4c47;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
          /* &::before {
            display: inline;
            content: "⚠ ";
          }*/
        }
      }
    }
    @include Btn(11.25, 2.5);
    @include BtnCancel(180, 40);
  }
}
