.sortedModal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: fixed;
  left: 40%;
  width: 200px;
  height: 120px;
  z-index: 1000;
  border-radius: 4px;
  background-color: #fff;
  box-shadow:
    0 1px 0 0 rgba(63, 63, 68, 0.05),
    0 4px 4px 0 rgba(0, 0, 0, 0.25);
  padding: 12px 20px 12px 12px;
}
