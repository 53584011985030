.ymaps-2-1-79-gotoymaps__container,
.ymaps-2-1-79-gototech {
  display: none !important;
}

.map-container-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.map-container {
  position: relative;
  width: 100%;
  height: 100%;
}
