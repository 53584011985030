@import "../../../styles/variables";
@import "../../../styles/editor_function";
@import "../../../styles/button";
@import "../../../styles/descriptionBlock.scss";
@import "../../../styles/title";
@import "../../../styles/InputTextarea";

.functionList {
  grid-area: mapcontent;
  @include EditorFunctionContainer(32, 32);
  @include FunctionEditorHeader(flex, 20);
  @include Title(36);
  @include Btn(11.25, 2.5);
  .functionBlockContainer {
    box-shadow: $BXShadow;
    background: $BgColor;
    border-radius: $BorderRadius;
    padding: 32px;
    .functionsBlock {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 32px;
      @include Description;
      input {
        @include InputTextarea(100%, 2.75);
        background: $BgColor;
      }
      .new_group {
        input {
          @include InputTextarea(100%, 2.125);
        }
      }

      .descriptionBlock_second,
      .descriptionBlock_third {
        display: flex;
        flex-direction: column;
        gap: 4px;
      }
      .descriptionBlock_second {
        input {
          padding: 12px;
          border: 1px solid $Color7;
          border-radius: 2px;
          box-shadow: 0 0 1px 1px $Color7;
          &:focus {
            border-color: $Color11;
            box-shadow: 0 0 1px 1px $Color11;
          }
          &.disabled {
            background-color: #ebebeb;
          }
        }
      }

      p {
        color: #ec4c47;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
      }
    }
    .descriptionBlock_notes {
      .functionNotes {
        width: 100%;
        height: 150px;
        display: flex;
        flex-direction: column;
        margin-top: 32px;
      }
      textarea {
        width: 100%;
        height: 100%;
        outline: none;
        padding: 12px 0 12px 12px;
        border: 1px solid #e5e5eb;
        box-shadow: 0 0 1px 1px #e5e5eb;
        border-radius: 2px;
        overflow: hidden;
        overflow-y: scroll;
        resize: none;
        &:focus {
          border-color: $Color11;
          box-shadow: 0 0 1px 1px $Color11;
        }
        &.disabled {
          background: #ebebeb;
        }
        &::-webkit-scrollbar {
          width: 6px;
        }
        &::-webkit-scrollbar-track {
          border-radius: 50px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 50px;
          background-color: $Color11;
        }
      }
    }
  }

  .statusBlock {
    margin-top: 30px;
    box-shadow: $BXShadow;
    background: $BgColor;
    border-radius: $BorderRadius;
  }
  .group_container {
    display: flex;
    align-items: center;
    .group_image {
      &.disabled {
        svg {
          path {
            fill: #9ea0a5;
          }
        }
      }
    }
    .group_title {
      color: #215ca7;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 148%;
      text-decoration-line: underline;
      cursor: pointer;
      &.disabled {
        color: #9ea0a5;
      }
    }
  }
}
