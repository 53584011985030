@import "../../styles/variables";
@import "../../styles/ul";
@import "../../styles/title";
@import "../../styles/InputTextarea";
@import "../../styles/span";

.transportMenu {
  grid-area: list;
  width: 100%;
  height: 100%;
  padding: 32px 20px 0 20px;
  box-shadow: 1px 0 4px 0 rgba(0, 0, 0, 0.1);
  background: $BgColor;
  .transportMenuHeader {
    align-items: baseline;
    padding-bottom: 10px;
    border-bottom: $BorderBottom;
    @include Title(24);
    .btnRemove {
      width: 16px;
      height: 16px;
      cursor: pointer;
      margin-left: auto;
    }
  }
  .searchTransport {
    border: $BorderBottom;
    border-radius: 3px;
    background-color: $Color5;
    .searchImage {
      background-color: $Color5;
      padding-left: 9px;
      padding-right: 10px;
    }
    input {
      @include InputTextarea(16.5, 2.125);
      background-color: $Color5;
      color: $Color2;
    }
  }
  .transportFunction {
    .transportButton {
      margin-top: 8px;
      padding-left: 10px;
      span {
        @include Span;
        padding-top: 8px;
        padding-bottom: 8px;
      }
      &.active {
        background-color: $Color5;
      }
    }
    @include List_Ul;
    li {
      margin: 4px 0 4px 0;
      .transportName {
        @include Span;
        width: 140px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &.active {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        background-color: $Color5;
      }
    }
  }
}
