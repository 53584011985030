@import "../../styles/editor_function";
@import "../../styles/variables";
@import "../../styles/button";
@import "../../styles/title";
@import "../../styles/InputTextarea";
@import "../../styles/buttonSearch";

.adminPageList {
  @include EditorFunctionContainer(32, 30);
  @include FunctionEditorHeader(flex, 20);
  @include Title(36);
  @include Btn(11.25, 2.5);
  @include Search(7.375, 2.75);
  .adminPageBlockContainer {
    display: flex;
    box-shadow: $BXShadow;
    background: #ffffff;
    border-radius: $BorderRadius;
    .adminSetting {
      padding: 32px;
    }
    .adminBlockSearch {
      .admin_search {
        background-color: $BgColor;
        border: $BorderBottom;
        width: 35%;
        .adminImage {
          padding: 10px;
          svg {
            path {
              fill: #9ea0a5;
            }
          }
          background-color: $BgColor;
        }
        .searchInputAdmin {
          @include InputTextarea(19.125, 2.75);
          background-color: $BgColor;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.036px;
        }
      }
    }
    .tableBlock {
      border-top: $Border2;
    }
    .cleanChoice,
    .showOnly {
      color: #215ca7;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 148%;
    }
    .searchByDate {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 148%;
    }
  }
}
