@import "../../../../styles/editor_function";
@import "../../../../styles/button";
@import "../../../../styles/InputTextarea";

.amplify-expander__header {
  display: flex;
  background: white;
  border-radius: 4px;
  &:hover {
    background: #eff0f0;
  }
}
.amplify-expander__trigger {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  padding-left: 32px;
  min-height: 60px;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  color: #646464;
}
.amplify-expander__icon {
  padding-right: 10px;
}

.settingsContainer {
  min-height: 250px;
}
.settingsTitle {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  padding-left: 32px;
  min-height: 50px;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  color: #646464;
}
.settingsBlockContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 20px 32px 32px 32px;
  gap: 40px;
  span {
    color: var(--grayscale-black, #1a141f);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 148%;
  }
}
.statusItem,
.criteria,
.criteriaParams,
.criteriaValue,
.valueBody {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;
}

.statusChoice {
  &.disabled {
    background-color: #ebebeb;
  }
}

.criteriaHeader {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.criteriaButtons {
  margin-left: 45px;
}
.criteriaParams {
  .params {
    margin-bottom: 8px;
    p {
      color: red;
      font-size: 14px;
      font-weight: 400;
    }
  }
}
.criteriaValue {
  .valueHeader {
    margin-bottom: 8px;
  }
  .valueBody {
    .value {
      display: flex;
      align-items: center;
      margin-bottom: 18px;
      .value_select {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        p {
          color: red;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
    .value_input {
      @include InputTextarea(100%, 2.75);
      padding: 14px;
      border: 1px solid #e5e5eb;
      border-radius: 2px;
      box-shadow: 0 0 1px 1px #e5e5eb;
      &.disabled {
        background-color: #ebebeb;
      }
    }
    .delete_criteria {
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}
.btnAddCriteria {
  height: 44px;
  width: 200%;
  button {
    .addCriteriaContainer {
      display: flex;
      text-align: left;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 20px;
    }
    width: 100%;
    text-align: center;
    border-radius: 2px;
    background: #ffffff;
    &.disabled {
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23ABA7AFFF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
      span {
        color: #aba7af;
      }
    }
    &.active {
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23215CA7FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
      span {
        color: #215ca7;
      }
      svg {
        path {
          fill: #215ca7;
        }
      }
    }
    &.disabled {
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23ABA7AFFF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
      span {
        color: #aba7af;
      }
    }
  }
  span {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
}
