@import "../../styles/variables";
@import "../../styles/ul";
@import "../../styles/span";

.menu {
  grid-area: menu;
  z-index: 10;
  box-shadow: 1px 0 4px 0 rgba(0, 0, 0, 0.1);
  background: $BgColor;
  .avatarUser {
    margin-top: 32px;
    svg {
      width: 74px;
      height: 74px;
    }
  }
  .userName {
    margin-top: 8px;
    border-bottom: $BorderBottom;
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 15px;
      letter-spacing: -0.0364583px;
      color: $Color1;
      text-align: center;
    }
    .job {
      @extend p;
      margin-bottom: 25px;
      margin-top: 8px;
      line-height: 12px;
      letter-spacing: 0.4px;
      color: $Color2;
    }
  }
  .navigation {
    margin-top: 9px;
    @include List_Ul;
    li {
      margin-left: 25px;
      svg {
        &.active {
          path {
            stroke: $Color3;
          }
        }
      }
      span {
        @include Span;
        &.active {
          color: $Color3;
        }
        &:hover {
          text-decoration: underline;
          color: $Color3;
        }
      }
    }
  }
}
