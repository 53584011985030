@import "../../styles/variables";
header {
  grid-area: header;
  background-color: $Color14;
  height: 2.875rem;
  z-index: 1001;

  .headerLogo {
    margin-left: 20px;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .headerSelector {
    margin-top: 8px;
    margin-right: 48px;
    margin-bottom: 8px;
  }
}
