@import "../../../styles/editor_function";
@import "../../../styles/variables";
@import "../../../styles/button";
@import "../../../styles/title";
@import "../../../styles/InputTextarea";

.settingsModuleList {
  grid-area: mapcontent;
  @include EditorFunctionContainer(32, 30);
  @include FunctionEditorHeader(flex, 20);
  @include Title(36);
  @include Btn(11.25, 2.5);
  .settingsModuleBlockContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    box-shadow: $BXShadow;
    background: #ffffff;
    border-radius: $BorderRadius;

    .settingsItemBlock {
      padding: 32px;
      .descriptionBlock,
      .settingsSim {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 32px;

        span {
          font-weight: 400;
          font-size: 16px;
          line-height: 148%;
        }
        input {
          height: 44px;
          padding: 12px;
          border: 1px solid $Color7;
          border-radius: 2px;
          box-shadow: 0 0 1px 1px $Color7;

          &:focus {
            border-color: $Color11;
            box-shadow: 0 0 1px 1px $Color11;
          }
          &.disabled {
            background-color: #ebebeb;
          }
        }
      }
    }
    .versionFirmware,
    .simNumber,
    .simPin,
    .logSize {
      background-color: #ebebeb;
      cursor: none;
      pointer-events: none;
      box-shadow: none;
      border: none;
    }
    input {
      width: 100%;
      height: 100%;
      background: $BgColor;
      font-weight: 400;
      font-size: 0.875rem;
      outline: none;
      line-height: 140%;
      cursor: pointer;
    }
  }
  p {
    color: #ec4c47;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
  /* .disconnected {
    color: #EC4C47;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .connected {
    color: #4CAF50;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
*/
  .logBlock {
    margin-top: 30px;
    box-shadow: $BXShadow;
    background: $BgColor;
    border-radius: $BorderRadius;
  }
  .moduleBlockLine {
    width: 100%;
    border-top: 3px solid #e4e7eb;
  }
  .moduleBlock_title {
    color: #646464;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.05px;
  }
}
