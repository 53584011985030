.wrapper_page {
}
.page_container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
}
.page_title {
  color: #1f5ea8;
  text-align: center;
  font-size: 64px;
  font-style: normal;
  font-weight: 900;
  line-height: 140%;
}
.page_item {
  margin-top: 20px;
}
