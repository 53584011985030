@import "../../../styles/title";
@import "../../../styles/InputTextarea";
@import "../../../styles/button";
@import "../../../styles/button_cancel";
@import "../../../styles/variables";

.overlay_history {
  grid-area: mapcontent;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 4;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  .functionHistoryContainer {
    position: absolute;
    left: 60%;
    top: 50%;
    transform: translate(-60%, -50%);
    .functionHistoryForm {
      border-radius: 4px;
      width: 465px;
      max-height: 1000px;
      background-color: $BgColor;
      padding: 32px;

      .functionHistoryHeader {
        display: flex;
        align-items: center;
        @include Title(24);
        img {
          width: 16px;
          height: 16px;
          margin-left: auto;
          cursor: pointer;
        }
      }
      .choiceTimePeriod {
        display: flex;
        flex-direction: column;
        position: sticky;
        z-index: 999999999;
        .choicePeriod {
          margin-top: 12px;
        }
        .timePeriod {
          width: 65%;
        }
      }
      .toggle {
        width: 65%;
      }

      .formFooter {
        display: flex;
        justify-content: space-between;
        margin-top: 16px;

        @include Btn(10.25rem, 2.5rem);
        @include BtnCancel(180px, 40px);
      }
    }
  }
}
