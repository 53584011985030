@import "../../../styles/variables";
@import "../../../styles/ul";
@import "../../../styles/InputTextarea";
@import "../../../styles/button";

.tableContainer {
  margin-bottom: 62px;
  box-shadow: $BXShadow;
  background: #ffffff;
  border-radius: $BorderRadius;
  .searchBlock {
    display: flex;
    margin-top: 20px;
    border: $Border2;
    border-radius: 2px;
    background-color: $BgColor;
    align-items: center;
    box-shadow: 0 0 1px 1px $Color7;
    input {
      @include InputTextarea(11.375, 2.25);
      padding: 8px 12px;
      color: $Color6;
    }
    svg {
      margin-right: 14px;
    }
    &:hover {
      border-color: hsl(0, 0%, 80%);
    }
    &.active {
      border: $Border2;
      box-shadow: 0 0 1px 1px $Color6;
    }
  }
  .tableItems {
    margin-top: 20px;
    border-top: $Border2;
    max-height: 360px;
  }
  .btn_delete {
    width: 60px;
    height: 30px;
    background-color: #ec4c47;

    span {
      color: #ffffff;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
    }
  }
  .sc-hmdomO {
    padding: 24px;
  }
  .rdt_TableHeadRow {
    color: $Color12;
    font-weight: 400;
    font-size: 14px;
  }
  .rdt_TableCell {
    color: #1a141f;
    font-size: 14px;
    font-weight: 300;
  }
}
