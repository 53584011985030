@import "../../../styles/variables";
@import "../../../styles/InputTextarea";
@import "../../../styles/button";
@import "../../../styles/button_cancel";

.overlay {
  grid-area: content;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  overflow-y: hidden;
  .createContainer {
    position: absolute;
    left: 60%;
    top: 60%;
    transform: translate(-60%, -60%);
    .createForm {
      border-radius: 0.25rem;
      width: 700px;
      max-height: 600px;
      background-color: $BgColor;
      padding: 32px;
      img {
        width: 16px;
        height: 16px;
        cursor: pointer;
      }
    }
    .createHeader {
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      color: #646464;
      padding: 0;
      margin: 0;
    }
    .createBlock {
      .createBlockColumns {
        display: flex;
        .createBlockFirstColumn,
        .createBlockSecondColumn {
          width: 50%;
          box-sizing: border-box;
          label {
            color: $Color1;
            font-size: 16px;
            font-weight: 700;
          }
        }
        input {
          @include InputTextarea(100%, 2.75rem);
          border: 1px solid #646464;
          padding: 12px;
          border-radius: 4px;
        }
        .input__error {
          border: 2px solid #ec4c47;
        }
      }
      .roles,
      .gender {
        width: 100%;
      }
      .react-datepicker-wrapper {
        display: flex;
      }
      p {
        color: #ec4c47;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        margin: 0;
        padding: 0;
      }

      .createButtonUser {
        margin-top: 20px;
        display: flex;
        justify-content: flex-end;

        .btn_save {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 50%;
          height: 2.5rem;
          background: $Color9;
          border-radius: 4px;
          transition: all 500ms ease;
          span {
            font-style: normal;
            font-size: 16px;
            line-height: 23.68px;
            text-align: center;
            color: $BgColor;
          }
        }

        @include BtnCancel(50%, 40px);
      }
    }
  }
}
