@import "../styles/variables";
@mixin Search($width, $height) {
  .btn_search {
    display: flex;
    justify-content: center;
    align-items: center;
    width: #{$width}rem;
    height: #{$height}rem;
    background: $Color9;
    border-radius: 4px;
    transition: all 500ms ease;
    span {
      font-style: normal;
      font-size: 16px;
      line-height: 23.68px;
      text-align: center;
      color: $BgColor;
    }
    &:hover {
      background: lighten($Color9, 5%);
      &:focus {
        outline: none;
        box-shadow: 0 0 0 1px $Color9;
      }
    }
    &.active {
      background: $Color10;
      &:hover {
        &:focus {
          box-shadow: 0 0 0 1px $Color10;
        }
      }
    }
  }
}
