@mixin EditorFunctionContainer($MgTop, $MgLeft) {
  .editorContainer,
  .functionContainer,
  .settingsModuleContainer,
  .adminPageModuleContainer,
  .logBlock {
    margin-top: #{$MgTop}px;
    margin-left: #{$MgLeft}px;
    width: 95%;
  }
}

@mixin FunctionEditorHeader($DFlex, $MrBottom) {
  .functionHeader,
  .editorHeader,
  .settingsModuleHeader,
  .adminPageHeader {
    display: $DFlex;
    align-items: center;
    margin-bottom: #{$MrBottom}px;
  }
}

@media screen and (min-width: 1920px) {
  .editorContainer,
  .functionContainer,
  .settingsModuleContainer,
  .adminPageModuleContainer,
  .logBlock {
    width: 95%;
  }
}
