@import "../../styles/button.scss";

.chronology-overlay {
  position: fixed;
  top: 0;
  left: 300px;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.chronology-modal {
  // position: relative;
  // top: 50px;
  // left: -132px;
  width: 90%;
  height: 700px;
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
}

.modal-header {
  position: relative;
  padding: 15px 20px 12px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-header h2 {
  font-family: Roboto;
  color: rgba(100, 100, 100, 1);
  font-size: 24px;
  font-weight: 700;
}

.modal-content {
  display: flex;
  flex-direction: column;
  height: 592px;
}

.map-container {
  // flex: 1;
  width: 100%;
  height: 592px;
}

.controls {
  display: flex;
  gap: 5px;
  padding-left: 32px;
}

.controls-label {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
}

.controls-label-gps {
  margin-left: 20px;
  margin-right: 20px;
}

.controls-select {
  cursor: pointer;
  display: block;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #fff;
  font-size: 16px;
  outline: none;
}

.controls-select:focus {
  border-color: #1e5da8;
  outline: none;
  box-shadow: 0 0 0 3px rgba(38, 143, 255, 0.25);
}

.modal-close-button {
  width: 16px;
  height: 16px;
  cursor: pointer;
  margin-left: auto;
}

.modal-close-button:hover {
  color: #d70000;
}

.error-message {
  color: #d70000;
}

.controls-select.error {
  border: 1px solid red;
}
.popup-chronology-modal {
  .leaflet-popup-content-wrapper {
    background-color: #1665d8;
    border-radius: 4px;
  }

  .leaflet-popup-content {
    margin: 7px 23px 7px 7px;
    line-height: 1.3;
    font-size: 14px;
    min-height: 1px;
    color: #fff;
  }

  .leaflet-popup-tip {
    background-color: #1665d8;
  }
}
.leaflet-container a.leaflet-popup-close-button {
  color: #fff;
}
.leaflet-container a.leaflet-popup-close-button:hover {
  color: #d5d5d5;
}
